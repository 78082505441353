@import "../../assets/scss/variables";

.image-row {
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  max-width: 100%;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 1px;
  grid-column-gap: 1px;

  @include mobile {
    grid-template-columns: repeat(2, 1fr);
  }

  @include tablet-only {
    grid-template-columns: repeat(3, 1fr);
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
  }

  img {
    height: 80px;
    margin: 1px;
    filter: grayscale(1);
    padding: 15px;
    width: 100%;
    object-fit: contain;
    background: white;
    cursor: pointer;

    &:hover {
      filter: grayscale(0);
    }
  }
}
