/* purgecss start ignore */
.section-heading {
  margin-bottom: 30px;

  &--center {
    text-align: center;
  }

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }

  &--sticky {
    position: sticky;
    top: 0.75rem;
  }

  .title {
    font-weight: 900;
  }
}
/* purgecss end ignore */
