@use "sass:color";

$primary: #89715B;
$primary-light: color.adjust($primary, $lightness: +10%);
$radius: 0;
$dark: #363636;
$grey: #4B4B4B;
$text: #B6B6B6;
$title-color: white;
$content-heading-color: white;
$link: $primary;
$link-hover: $primary-light;

$card-background-color: $grey;
$card-radius: $radius;

$content-blockquote-background-color: color.adjust($grey, $lightness: -10%);

@import "../../../node_modules/bulma/sass/utilities/all";
