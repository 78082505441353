@import "../../assets/scss/variables";
@import "~swiper/swiper.min.css";
.swiper-cards{overflow:visible}.swiper-cards .swiper-slide{transform-origin:center bottom;-webkit-backface-visibility:hidden;backface-visibility:hidden;overflow:hidden}

.certifications {
  &-section {
    overflow: hidden;
  }

  .swiper-cards {
    width: 500px;
    max-width: calc(100% - 1.5rem);

    @include until($desktop) {
      width: 100%;
    }
  }

  &__item {
    box-sizing: border-box;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-color: $grey-dark;
    padding: 35px 25px;
    position: relative;
    text-align: left;

    &__title {
      color: $white;
      font-weight: bold;
      margin-bottom: 20px;
      font-size: 28px;
    }

    &__content {
      max-height: 400px;
      overflow: hidden;
      position: relative;
      transition: all 0.2s ease-out;

      &.is-active {
        max-height: 1200px;

        @include mobile {
          overflow: auto;
        }

        &:before {
          background: linear-gradient(0deg, transparent, transparent);
        }
      }

      &:before {
        content: "";
        width: 100%;
        height: 100px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0deg, $grey 15%, transparent);
        pointer-events: none;
        z-index: 1;
      }
    }

    &__icon {
      width: 65px;
      position: absolute;
      top: -20px;
      left: -20px;
      z-index: 1;
    }

    &__read-more {
      margin-left: auto;
      margin-right: auto;
      background: transparent;
      color: $white;
      box-shadow: none;
      outline: none;
      border: 0;
      font-family: $family-primary;
      -webkit-appearance: none;
      cursor: pointer;
      font-size: 16px;
      display: block;
      text-decoration: underline;
      margin-top: -20px;
      transition: all 0.2s ease-out;
      transform: translateY(200px);

      &.is-active {
        transform: translateY(0);
      }
    }
  }
}
