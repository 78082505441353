@import "../../assets/scss/variables";

.intro-card {
  position: relative;
  z-index: 1;
  margin-top: calc(-0.75rem - 85px);
  min-height: 436px;

  @include mobile {
    margin-top: calc(-0.75rem - 20px);
  }

  .card-content {
    padding: 2.5rem;

    @include mobile {
      padding: 1.5rem;
    }

    h3 {
      font-weight: 900;
      margin-bottom: 25px;
    }
  }

  padding-bottom: 65px;
}

.hobbies {
  display: flex;
  align-items: center;
  margin: -0.75rem;
  margin-top: calc(-2.25rem - 65px);
  position: relative;
  z-index: 1;

  @include mobile {
    overflow: auto;
    padding: 0 0.75rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-color: $primary;
    width: 140px;
    height: 140px;
    flex: 0 0 140px;
    position: relative;
    margin: 0.75rem;

    &:first-child {
      margin-left: auto;
    }

    &:last-child {
      margin-right: auto;
    }

    @include mobile {
      width: 100px;
      height: 100px;
      flex: 0 0 100px;
    }

    &__icon {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: $white;

      @include mobile {
        font-size: 18px;
      }
    }

    &__title {
      justify-self: end;
      width: 100%;
      background-color: $grey;
      text-align: center;
      padding: 4px 10px;

      @include mobile {
        font-size: 10px;
      }
    }
  }
}
