@import "../../assets/scss/variables";

.social-buttons {
  .button.is-dark {
    background: $grey;
    height: 40px;
    width: 40px;
    padding: 0;

    &:hover {
      background: bulmaLighten($grey, 5%);
    }
  }
}
