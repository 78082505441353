@import "../../assets/scss/variables";

.skills {
  &__item {
    width: 100%;
    background: $grey;
    text-align: center;
    position: relative;
    margin-top: 30px;
    padding: 30px 5px 13px;

    &__icon {
      width: 60px;
      height: 60px;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: calc(100% - 30px);

      @include mobile {
        width: 45px;
        height: 45px;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__level {
      position: absolute;
      top: 8px;
      left: 10px;
      width: 25px;
    }

    &__title {
      font-size: 18px;
      margin-top: 10px;

      @include mobile {
        font-size: 14px;
      }
    }
  }
}
