@import "../../assets/scss/variables";

.timeline-item {
  display: flex;
  padding-top: 64px;
  position: relative;
  padding-bottom: 20px;

  @include mobile {
    padding-left: 20px;
  }

  &:first-child:after {
    content: "";
    background-image: linear-gradient(180deg, rgba(54,54,54,0.00) 0%, #363636 100%);
    transform: scale(-1);
    position: absolute;
    top: 0;
    z-index: 1;
    width: 10px;
    height: 35px;
    left: 97px;

    @include mobile {
      left: 1px;
    }
  }

  &:last-child:after {
    content: "";
    background-image: linear-gradient(180deg, rgba(54,54,54,0.00) 0%, #363636 100%);
    position: absolute;
    bottom: 0;
    left: 97px;
    z-index: 1;
    width: 10px;
    height: 50px;

    @include mobile {
      left: 1px;
    }
  }

  &:before {
    content: "";
    height: 100%;
    width: 2px;
    background: $text;
    position: absolute;
    left: 97px;
    top: 0;

    @include mobile {
      left: 1px;
    }
  }

  &__logo {
    width: 80px;
    height: 80px;
    flex: 0 0 80px;
    border-radius: 100%;
    background: $white;
    padding: 16px;
    margin-top: -64px;
    margin-right: 36px;
    position: sticky;
    top: 0.75rem;

    @include mobile {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__date {
    font-size: 18px;
    color: $white;
    margin-bottom: 9px;
    bottom: 100%;
    position: absolute;

    &:before {
      content: "";
      left: -23px;
      top: 7px;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background: $primary;
      position: absolute;
    }
  }

  &__content {
    position: relative;

    .content {
      margin-top: 40px;
    }

    &__heading {
      background: bulmaDarken($grey, 5%);
      padding: 1.5rem;
      margin: -1.5rem;

      position: sticky;
      top: -1px;
      z-index: 1;
      display: flex;
      align-items: center;

      &__logo {
        display: none;
        align-items: center;
        justify-content: center;
        background: white;
        height: 50px;
        width: 50px;
        flex: 0 0 50px;
        position: relative;
        padding: 8px;
        border-radius: 100%;
        margin-right: 15px;

        @include mobile {
          display: flex;
        }

        img {
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    &__title {
      font-size: 18px;
      font-weight: 900;
      color: $white;
    }

    &__subtitle {
      font-size: 14px;
    }
  }
}
