@import "variables";
@import "bulma";
@import "~animate.css/source/_base.css";
@import "~animate.css/source/_vars.css";
@import "~animate.css/source/fading_entrances/fadeInLeft.css";
@import "~animate.css/source/fading_entrances/fadeInDown.css";
@import "~animate.css/source/fading_entrances/fadeInUp.css";

html {
  background-color: $dark;
}

strong {
  color: inherit;
}

.section--full-height {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: bulmaDarken($dark, 3%);
}

.section {
  @include mobile {
    padding-left: 0;
    padding-right: 0;
  }

  .container {
    max-width: 100%;
  }
}

.is-centered-vertically {
  display: flex;
  align-items: center;
}

.animated {
  &.delay-250ms {
    animation-delay: 250ms;
  }

  &.delay-500ms {
    animation-delay: 500ms;
  }

  &.delay-600ms {
    animation-delay: 600ms;
  }
}

[data-tooltip] {
  transition: all 0.2s ease-in;
}

.button {
  transition: all 0.1s ease-in;
}
