@import "../../assets/scss/variables";

.achievements {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: -2rem;
  margin-bottom: -2rem;

  @include desktop {
    justify-content: space-around;
  }

  @include mobile {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .achievement-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem 3rem;
    text-align: center;
    min-width: 210px;

    @include mobile {
      padding: 1rem;
      min-width: 147px;
    }

    &__highlight {
      font-size: 48px;
      color: $primary;
      font-weight: 900;
      line-height: 1;
      margin-bottom: 5px;
    }

    &__content {
      text-transform: uppercase;
      font-size: 14px;
    }

    &__icon {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      background: $grey;
      border-radius: 100%;
      margin-bottom: 10px;
    }
  }

  .achievement-icon {
    color: #FFD56F;
    font-size: 95px;
    margin-top: -90px;
  }
}
