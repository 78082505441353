@import "../../assets/scss/variables";

.hero-section {
  min-height: 656px;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  @include mobile {
    min-height: 436px;
  }

  .field:hover {
    .download-icon {
      background: bulmaDarken($primary, 2.5%);
    }

    .button {
      background: bulmaDarken($dark, 2.5%);
    }
  }

  .download-icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary;
    font-size: 20px;
    transition: all 0.1s ease-in;

    .icon {
      color: $white;
    }
  }

  &__bg {
    position: absolute !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 100% !important;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      object-fit: cover;
      object-position: center -30px;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      background-image: linear-gradient(90deg, rgba(54, 54, 54, 0) 77%, #363636 100%), linear-gradient(180deg, rgba(54, 54, 54, 0) 45%, #363636 98%), radial-gradient(circle at 104% 0%, rgba(101, 101, 101, 0) 0%, #363636 88%);
      height: 100%;
      z-index: 1;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    width: 100%;

    &__intro {
      margin-bottom: 28px;

      p {
        font-weight: normal;
        font-size: 36px;
        color: #B6B6B6;
        line-height: 1;

        @media screen and (max-width: 375px) {
          font-size: 26px;
        }

        &:last-child {
          font-weight: lighter;
          font-size: 24px;

          @media screen and (max-width: 375px) {
            font-size: 18px;
          }
        }
      }

      h1 {
        font-weight: 900;
        font-size: 48px;
        color: #FFFFFF;

        @media screen and (max-width: 375px) {
          font-size: 35px;
        }
      }
    }
  }

  .social-buttons {
    .button.is-dark {
      background: $grey;

      &:hover {
        background: bulmaLighten($grey, 5%);
      }
    }
  }

  .social-buttons {
    margin-top: 40px;
  }
}
