@import "../../assets/scss/variables";

.contact-email {
  display: flex;
  align-items: center;
  margin-top: 50px;

  &__link {
    font-size: 60px;
    text-align: center;
    display: block;
    font-weight: lighter;

    @include mobile {
      font-size: 40px;
    }
  }

  &__copy {
    height: 50px;
    margin-left: 20px;
    cursor: pointer;

    &.button.is-dark.is-outlined {
      color: $text;
      border-color: transparent;
      height: 50px;
      font-size: 20px;
      width: 50px;
      padding: 0;
      margin-bottom: -5px;

      &:hover {
        background: transparent;
        color: $white;
      }
    }
  }
}
